<template>
	<section>
		<b-container fluid class="pagecontainer">
			<b-row>
				<b-col>
					<b-alert variant="danger" v-if="error" show dismissible>
						ERROR: {{error}}
					</b-alert>
				</b-col>
			</b-row>
			<b-row>
				<b-col>
					<b-card no-body>
						<b-card-text>
							<span class="pl-2 h3">
								<slot name="title">
									Games
								</slot>
							</span>
							<div class="float-right">
								<b-form inline>
									<!--<b-form-input v-model.trim="searchText" placeholder="search text" />-->
									<b-btn variant="success" @click="doReset">
										<fa-icon icon="sync" />
									</b-btn>
									<b-btn variant="success" @click="doGetGames" v-if="games && games.nextPageToken">
										<fa-icon icon="chevron-down" />
									</b-btn>
								</b-form>
							</div>
							<br />
							<b-table striped hover responsive :fields="gamesfields" :items="games.games">
								<template #cell(id)="data">
									<i>{{ data.value }}</i>

									<section class="pt-4">
										Actions:
										<div>
											<b-button-group>
												<b-btn :to="{ name: 'managegame', params: { id: data.item.id } }">View</b-btn>
											</b-button-group>
										</div>
									</section>

									<section class="pt-4">
										Properties:
										<div>
											Created on: {{ data.item.createdOnUtcMs ? data.item.createdOnUtcMs : "!!" }}
										</div>
									</section>
								</template>
								<template #cell(state)="data">
									<vue-json-pretty v-if="data.item" :data="data.item" :deep="3" />
								</template>
							</b-table>
						</b-card-text>
					</b-card>
				</b-col>
			</b-row>
		</b-container>
	</section>
</template>
<script lang="js">
	import { mapActions } from 'vuex';
	import DEBUG from '@/common/DEBUG';
	import VueJsonPretty from 'vue-json-pretty';
	import 'vue-json-pretty/lib/styles.css';

	export default {
		name: 'manage_games',

		components: {
			VueJsonPretty
		},

		data() {
			return {
				baseurl: '//' + window.location.hostname,

				error: null,
				isprocessing0: false,
				searchText: null,
				gamesfields: [
					{ key: 'id', label: 'Id', tdClass: "column1" },
					{ key: 'state', label: 'State' },
				],
				games: {
					nextPageToken: null,
					games: []
				},
			}
		},

		async mounted() {
			this.doGetGames();
		},

		methods: {
			...mapActions({
				getGames: "getGames",
				getAllGames: "getAllGames"
			}),

			async doReset() {
				const that = this
				DEBUG.log('doReset...')

				that.error = null;
				try {
					const searchText = this.searchText;
					const results = await that.getGames({ searchText });
					DEBUG.log('doReset - RESULTS - ', results);
					that.games.nextPageToken = results.nextPageToken;
					that.games.games = results.games;
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${that.error}`)
				}
			},

			async doGetGames() {
				const that = this
				DEBUG.log('doGetGames...')

				that.error = null;
				try {
					const { nextPageToken } = that.games;
					const filter = { nextPageToken };
					const results = await that.getAllGames(filter);
					DEBUG.log('doGetGames - RESULTS - ', results)
					that.games.nextPageToken = results.nextPageToken;
					that.games.games.push(...results.games.map(u => {
						u.isprocessing = false;
						return u;
					}));
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${that.error}`)
				}
			},

			async doCreateGame(item) {
				DEBUG.log('doCreateGame...')

				const that = this
				const { id } = item

				that.error = null;
				try {
					const game = await that.createGame(id);
					if (game) {
						DEBUG.log('GAME - ', game)
						alert('game created - ' + game.id);
					}
				} catch (error) {
					that.error = JSON.stringify(error)
					that.notifyError(`an error occurred - ${error.message}`)
				}
			},

		}
	}
</script>
<style>
	.pagecontainer {
		max-height: 85vh;
		overflow-y: auto;
	}

	.column1 {
		width: 25%;
	}
</style>